<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[{ expanded: !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) }, skin === 'semi-dark' ? 'menu-dark' : 'menu-light']"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot name="header" :toggleVerticalMenuActive="toggleVerticalMenuActive" :toggleCollapsed="toggleCollapsed" :collapseTogglerIcon="collapseTogglerIcon">
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link class="navbar-brand pl-25">
              <span class="brand-logo">
                <transition name="fade" mode="out-in">
                  <b-img :src="appLogoImage" alt="logo" v-if="!isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered)" key="pinned" />
                  <b-img :src="appLogoImageCollapsed" alt="logo" v-else class="icon" key="unpinned" />
                </transition>
              </span>
              <!-- <h2 class="brand-text text-white">
                {{ appName }}
              </h2> -->
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon icon="XIcon" size="20" class="d-block d-xl-none" @click="toggleVerticalMenuActive" />
              <feather-icon :icon="collapseTogglerIconFeather" size="20" class="d-none d-xl-block collapse-toggle-icon" @click="toggleCollapsed" />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      ref="ps"
      tagname="ul"
      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
          shallShadowTop = hasScrollingSpace
        }
      "
    >
      <vertical-nav-menu-items :items="MenuItems" class="navigation navigation-main" v-if="Menu != null" />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { useRouter } from 'vue2-helpers/vue-router'
import router from '@/router'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    FeatherIcon,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['Menu']),
    MenuItems() {
      return this.Menu
    },
  },
  methods: {
    ...mapActions(['LogOut']),
  },
  setup(props, { root }) {
    const { isMouseHovered, isVerticalMenuCollapsed, collapseTogglerIcon, toggleCollapsed, updateMouseHovered } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)
    const shallShadowTop = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))
    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'ChevronsRightIcon' : 'ChevronsLeftIcon'))

    const ps = ref({})

    const hasScrollingSpace = computed(() => {
      var element = ps.value.$el
      if (element) {
        const overflow = element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth
        const scrolledToBottom = element.scrollTop === element.scrollHeight - element.offsetHeight

        return overflow && !scrolledToBottom
      }
      return false
    })
    // App Name
    const { appName, appLogoImage, appLogoImageCollapsed } = $themeConfig.app

    return {
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,
      shallShadowTop,
      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
      appLogoImageCollapsed,
      ps,
      hasScrollingSpace,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';

.main-menu.menu-dark .navigation li.nav-item a.change-project-link:hover > * {
  transform: none !important;
  span {
    text-decoration: underline;
  }
}

.sidebar-footer {
  height: 250px;
  // background-color: #fff;
  z-index: 2000;
  width: 100%;

  ul {
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
}
.main-menu .main-menu-content {
  height: calc(100% - 8.9rem) !important;
}

.shadow-top {
  background: linear-gradient(180deg, #040f07 44%, rgba(4, 15, 7, 0.51) 73%, rgba(4, 15, 7, 0));
  display: none;
  position: absolute;
  z-index: 2;
  height: 50px;
  width: 100%;
  pointer-events: none;
  filter: blur(5px);
  margin-top: -1.5rem;
}
</style>
