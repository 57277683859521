<template>
  <div class="navbar-container d-flex content align-items-center py-50">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex pl-2">
      <h4 class="m-0 text-primary">{{ Project.displayName }}</h4>
      <b-link href="/app/projects" class="d-flex align-items-center p-0 ml-1 font-small-1"
        ><feather-icon size="12" icon="RepeatIcon" class="mr-25" /><span>Change Project</span></b-link
      >
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto mr-1">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <user-dropdown />
    </b-navbar-nav>

    <!-- <b-navbar-nav class="nav align-items-center ml-auto">
      <div class="pr-1">
        <portal-target name="nav-bar-right-column" slim />
      </div>
    </b-navbar-nav> -->
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue'
import AtlasLogo from '@/components/Layout/Logo/AtlasLogo.vue'
import UserDropdown from './UserDropdown.vue'
import DarkToggler from './DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    // Navbar Components
    DarkToggler,
    UserDropdown,
    AtlasLogo,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
