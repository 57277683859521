<template>
  <div class="action-bar navbar-container d-flex content align-items-center">
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex pl-2"><portal-target name="nav-bar-left-column" slim /></div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <div class="pr-2">
        <portal-target name="nav-bar-right-column" slim />
      </div>
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
