<template>
  <layout-vertical :class="{ 'action-bar-layout': ActionBar }">
    <router-view :key="$route.path" v-if="$route.meta.contentRoute && $route.meta.contentRoute === true" />
    <router-view v-else />

    <template #navbar="{ toggleVerticalMenuActive }">
      <div class="d-flex flex-column w-100">
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" class="info-bar" :class="{ shadow: !ActionBar }" />
        <!-- <portal-target name="action-bar" slim /> -->
        <transition name="slide-fade" mode="out-in">
          <actionbar v-if="ActionBar" class="action-bar" />
        </transition>
      </div>
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import { mapGetters } from 'vuex'
import Navbar from '../components/Navbar.vue'
import Actionbar from '../components/Actionbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    Actionbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  computed: {
    ...mapGetters(['ActionBar']),
  },
  created() {},
  methods: {},
}
</script>
