<template>
  <p class="clearfix mb-0 text-right">
    <!-- <router-link to="/" class="btn btn-primary"><feather-icon icon="PlusIcon" class="mr-25" /> Create item</router-link> -->
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
